<template>
    <CModal content-class-name="rename-popup" size="lg" backdrop="static" :visible="visible" @close="closePopup">
        <CModalHeader class="rename-popup__header" :close-button="false">
            <CModalTitle class="rename-popup__heading">Rename {{ entityName }}</CModalTitle>
        </CModalHeader>

        <CModalBody class="rename-popup__body">
            <CNInput v-model="name" required :label="`${entityName} Name`" :error="validationErrors['name']"
                     :invalid="!!validationErrors['name']" @blur="validateField"/>
        </CModalBody>

        <CModalFooter class="rename-popup__footer">
            <CButton class="rename-popup__button" variant="outline" color="primary" :disabled="loading"
                     @click="closePopup">
                Cancel
            </CButton>
            <CButton class="rename-popup__button" color="primary" :disabled="loading || !name" @click="rename">
                Rename
            </CButton>
        </CModalFooter>
    </CModal>
</template>

<script>
import rules from '@/utils/validator/rules'
import validator from '@/utils/validator'
import {mapActions} from 'vuex'
import {parseErrorFromResponse} from '@/utils/helper'

export default {
    name: 'Rename',
    inject: ['toast'],
    props: {
        visible: {
            type: Boolean,
            default: false,
        },
        data: {
            type: Object,
            default: () => ({}),
        },
    },
    emits: ['close', 'updateData'],
    data() {
        return {
            name: '',
            validator: {},
            validationErrors: {},

            loading: false,
        }
    },
    computed: {
        entityName() {
            return this.data.key.capitalize() || ''
        },
    },
    watch: {
        visible(visible) {
            if (!visible) {
                this.clearData()
            } else {
                this.name = this.data?.name
                this.setRules()
            }
        },
    },
    methods: {
        ...mapActions({
            renameEntity: 'renameEntity',
        }),

        closePopup() {
            this.$emit('close')
        },
        setRules() {
            this.validator = validator({
                name: [rules.required, rules.strMax(255)],
            })
        },
        validateField() {
            this.validationErrors.name = this.validator.validate('name', this.name)
        },
        clearData() {
            this.name = ''
            this.validator = {}
            this.validationErrors = {}
            this.loading = false
        },
        rename() {
            this.setRules()
            const validationResult = this.validator.validateAll({name: this.name})
            this.validationErrors = this.$deepClone(validationResult.validationErrors)

            if (validationResult.hasErrors) return

            this.loading = true
            this.renameEntity({...this.data, name: this.name})
                .then((response) => {
                    this.closePopup()
                    this.toast('info', response)
                    this.$emit('updateData')
                })
                .catch((error) => {
                    const errorsObject = error.response.data?.errors
                    const errors = parseErrorFromResponse(errorsObject)

                    if (errorsObject && errors) {
                        this.validationErrors = {
                            ...this.validationErrors,
                            ...errors,
                        }
                    }
                })
                .finally(() => {
                    this.loading = false
                })
        },
    },
}
</script>

<style lang="scss">
.rename-popup {
    max-width: 560px;
    padding: 8px;

    &__header {
        justify-content: center;
    }

    &__heading {
        font-weight: 600;
        font-size: 24px;
        line-height: 32px;
        letter-spacing: 0.2px;
        color: #1c262f;
    }

    &__footer {
        justify-content: center;
    }

    &__button {
        min-width: 140px;
    }
}
</style>
