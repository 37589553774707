<template>
    <CModal scrollable size="md" class="py-4" :visible="visible" @close="cancel">
        <CModalHeader>
            <CModalTitle>Archive Template(s)</CModalTitle>
        </CModalHeader>
        <CModalBody>
            <p class="text-center">Are you sure you want to archive the following template(s):</p>
            <ul class="mt-3">
                <li v-for="template in templates">
                    {{ template.name }}
                </li>
            </ul>
            <p v-for="error in validationErrors" class="small text-error mb-0 text-center mt-2">{{ error }}</p>
        </CModalBody>
        <CModalFooter class="flex justify-content-center">
            <CButton color="primary" variant="outline" :disabled="loading" @click="cancel">Cancel</CButton>
            <LoadingButton color="primary" :loading="loading" @click="confirm">Confirm</LoadingButton>
        </CModalFooter>
    </CModal>
</template>

<script>
import LoadingButton from "@/components/LoadingButton.vue";
import Templates from "@/api/v2/endpoints/Templates";
import apiErrorHandler from "@/mixin/apiErrorHandler";

export default {
    name: 'DeleteTemplateModal',
    components: {LoadingButton},
    mixins: [apiErrorHandler],
    inject: ['toast'],
    props: {
        visible: Boolean,
        templates: Array,
    },
    emits: ['cancel', 'delete'],
    data() {
        return {
            loading: false,
        }
    },
    methods: {
        confirm() {
            this.clearValidationErrors();
            this.toggleLoading();

            Templates
                .destroyMany(this.deletionData())
                .then(() => {
                    this.toast('info', 'Template(s) successfully archived!');
                    this.$emit('delete')
                })
                .catch(response => this.handleApiError(response))
                .finally(() => this.toggleLoading());
        },
        deletionData() {
            return {
                template_ids: this.templates.map(template => template.id)
            }
        },
        cancel() {
            this.clearValidationErrors();
            this.$emit('cancel');
        },
        toggleLoading() {
            this.loading = !this.loading;
        }
    }
}
</script>
