<template>
    <CModal content-class-name="moving-popup" size="lg" backdrop="static" :visible="visible" @close="closePopup">
        <CModalHeader class="moving-popup__header" :close-button="false">
            <CModalTitle class="moving-popup__heading">{{ templateName }}</CModalTitle>
        </CModalHeader>

        <CModalBody class="moving-popup__body">
            <div v-if="selectedScenarioName && selectedScenario" class="moving-popup__subheader">
                <CButton color="link" size="sm" @click="leaveFromScenario">
                    <CIcon name="cilArrowLeft" class="moving-popup__icon"/>
                </CButton>
                <h4 class="moving-popup__subheading">
                    {{ selectedScenarioName }}
                </h4>
            </div>

            <SelectingList v-if="selectedScenarioName && selectedScenario && !disabledNested" v-model="selectedPackage"
                           :list="packagesList" list-icon="cilFolder" class="moving-popup__list"/>
            <SelectingList v-else :key="scenariosList" v-model="selectedScenario" :list="scenariosList"
                           list-icon="cilLayers" class="moving-popup__list"
                           :action-icon="!disabledNested ? 'cilArRight' : ''" @action="openPackagesBySelectedScenario"/>
            <AddNewItem :button-text="addNewItemConfig.buttonText" :icon="addNewItemConfig.icon"
                        :placeholder="addNewItemConfig.placeholder" :pending="loading" :errors="errors"
                        class="moving-popup__new" @create="createNewItem"/>
            <div v-if=" selectedScenarioName && selectedScenario && selectedPackage" class="moving-popup__type">
                <label class="moving-popup__label">
                    <input v-model="templateType" class="moving-popup__radio" type="radio" value="primary_contract"/>
                    <span>Primary Contract</span>
                </label>
                <label class="moving-popup__label">
                    <input v-model="templateType" class="moving-popup__radio" type="radio" value="flow_down"/>
                    <span>Flowdown</span>
                </label>
            </div>
        </CModalBody>

        <CModalFooter class="moving-popup__footer">
            <template v-if="isShortcut">
                <CButton class="moving-popup__button" variant="outline" color="primary" @click="closePopup">
                    Close
                </CButton>
                <CButton class="moving-popup__button" :disabled="!selectedScenario" color="primary"
                         @click="shortcutHandler">
                    Add Shortcut
                </CButton>
            </template>

            <template v-else>
                <CButton class="moving-popup__button moving-popup__button--cancel" variant="outline" color="primary"
                         @click="closePopup">
                    Close
                </CButton>

                <CButton class="moving-popup__button" color="primary" :disabled="!selectedScenario || loading"
                         @click="moveHandler">
                    Move
                </CButton>

                <CButton class="moving-popup__button" color="primary" :disabled="!selectedScenario || loading"
                         @click="copyHandler">
                    Copy
                </CButton>
            </template>
        </CModalFooter>
    </CModal>
</template>

<script>
import SelectingList from '@/views/Templates/Components/SelectingList'
import AddNewItem from '@/views/Templates/Components/AddNewItem'
import {parseErrorFromResponse} from '@/utils/helper'
import {mapActions, mapGetters} from 'vuex'
import {omit} from 'lodash'

export default {
    name: 'MovingPopup',
    components: {AddNewItem, SelectingList},
    inject: ['toast'],
    props: {
        visible: {
            type: Boolean,
            default: false,
        },
        data: {
            type: Object,
            default: () => ({}),
        },
    },
    emits: ['close', 'updateData'],
    data() {
        return {
            selectedScenario: null,
            selectedPackage: null,

            selectedScenarioName: '',

            templateType: 'primary_contract',

            loading: false,
            errors: {},

            packagesList: [],
        }
    },
    computed: {
        ...mapGetters({
            scenarios: 'scenarios',
        }),

        isShortcut() {
            return !!this.data?.is_shortcut
        },
        disabledNested() {
            return !this.data?.nested
        },
        scenariosList() {
            return this.scenarios.map((el) => {
                return {
                    id: el.id,
                    name: el.name,
                    list: el.packages?.map((packageData) => ({
                        id: packageData.id,
                        name: packageData.name,
                    })),
                }
            })
        },

        templateName() {
            return (
                this.data?.name ||
                (this.isShortcut ? 'Add shortcut to' : 'Move or copy')
            )
        },
        addNewItemConfig() {
            if (this.selectedScenario && this.selectedScenarioName) {
                return {
                    buttonText: 'Create a new package',
                    icon: 'cilFolder',
                    placeholder: 'Package Name',
                }
            } else {
                return {
                    buttonText: 'Create a new scenario',
                    icon: 'cilLayers',
                    placeholder: 'Scenario Name',
                }
            }
        },
    },
    watch: {
        visible(visible) {
            if (visible) return

            this.loading = false
            this.selectedScenario = null
            this.selectedPackage = null
            this.selectedScenarioName = ''
            this.templateType = 'primary_contract'
            this.errors = {}
            this.packagesList = []
        },
    },
    methods: {
        ...mapActions({
            createNewScenario: 'createNewScenario',
            createNewPackage: 'createNewPackage',
            moveEntity: 'moveEntity',
            copyEntity: 'copyEntity',
            addShortcut: 'addShortcut',
            getScenariosList: 'getScenariosList',
        }),

        closePopup() {
            this.$emit('close')
        },
        openPackagesBySelectedScenario(data) {
            this.selectedScenario = data.id
            if (this.disabledNested) return
            this.selectedScenarioName = data.name
            this.packagesList = data.list
        },
        leaveFromScenario() {
            this.selectedPackage = null
            this.selectedScenarioName = ''
            this.packagesList = []
            this.templateType = 'primary_contract'
        },
        createNewItem(name) {
            if (!name) return

            if (this.selectedScenario && this.selectedScenarioName) {
                this.loading = true
                this.createNewPackage({
                    name,
                    collection_id: this.selectedScenario,
                })
                    .then(() => {
                        this.fetchScenarios()
                            .then(() => {
                                this.openPackagesBySelectedScenario({
                                    id: this.selectedScenario,
                                    name: this.selectedScenarioName,
                                    list:
                                        this.scenariosList.filter(
                                            (scenario) => scenario.id === this.selectedScenario,
                                        )[0]?.list || [],
                                })
                            })
                    })
                    .catch((error) => {
                        const errorsObject = error.response.data?.errors
                        const errors = parseErrorFromResponse(errorsObject)

                        if (errorsObject && errors) {
                            this.errors = {
                                ...this.errors,
                                ...errors,
                            }
                        }
                    })
                    .finally(() => (this.loading = false))
            } else {
                this.loading = true
                this.createNewScenario({name})
                    .then(() => this.fetchScenarios())
                    .catch((error) => {
                        const errorsObject = error.response.data?.errors
                        const errors = parseErrorFromResponse(errorsObject)

                        if (errorsObject && errors) {
                            this.errors = {
                                ...this.errors,
                                ...errors,
                            }
                        }
                    })
                    .finally(() => (this.loading = false))
            }
        },
        fetchScenarios() {
            return this.getScenariosList({
                params: {
                    sort_field: 'name',
                    sort_direction: 'asc',
                },
            })
        },
        moveOrCopyAdapter() {
            const dictionary = {
                'primary_contract': 1,
                'flow_down': 2
            }
            let data = omit(this.data, ['is_shortcut', 'nested', 'item_type', 'name'])
            if (data.templates) {
                const templates = data.templates.map((el) => {
                    const [template_id, type] = String(el)?.split('.')
                    if (type) {
                        return {
                            template_id: +template_id,
                            type: +type
                        }
                    } else {
                        return {
                            template_id: +template_id,
                            // type: dictionary[this.templateType]
                        }
                    }
                })

                data = {
                    ...data,
                    templates,
                }
            }

            if (this.selectedPackage) {
                data.to = 'package'
                data.to_id = this.selectedPackage
            } else {
                data.to = 'scenario'
                data.to_id = this.selectedScenario
            }

            if (data.from === 'scenario' && data.to === 'package') {
                data.templates = data.templates.map(template => ({...template, type: dictionary[this.templateType]}))
            }

            if (data.from === 'package' && data.to === 'package') {
                data.templates = data.templates.map(template => ({
                    template_id: template.template_id,
                    type_from: template.type,
                    type: dictionary[this.templateType]
                }))
            }

            if (data.from === 'package' && data.to === 'scenario') {
                data.templates = data.templates.map(template => ({
                    template_id: template.template_id, type_from: template.type, type: dictionary[this.templateType]
                }))
            }

            return data
        },
        moveHandler() {
            const data = this.moveOrCopyAdapter()
            this.loading = true
            this.moveEntity(data)
                .then((response) => {
                    this.closePopup()
                    this.toast('info', response)
                    this.$emit('updateData')
                })
                .catch((error) => {
                    this.toast('warning', error.response.data.message)
                })
                .finally(() => (this.loading = false))
        },
        copyHandler() {
            const data = this.moveOrCopyAdapter()

            this.loading = true
            this.copyEntity(data)
                .then((response) => {
                    this.closePopup()
                    this.toast('info', response)
                    this.$emit('updateData')
                })
                .catch((error) => {
                    this.toast('warning', error.response.data.message)
                })
                .finally(() => (this.loading = false))
        },
        shortcutHandler() {
            const typeDictionary = {
                primary_contract: 'primary',
                flow_down: 'secondary',
            }
            const data = {
                template_id: this.data.items,
            }

            if (this.selectedPackage) {
                data.add_to_folder_id = this.selectedPackage
                data.type = typeDictionary[this.templateType]
            } else {
                data.add_to_collection_id = this.selectedScenario
            }

            this.loading = true
            this.addShortcut(data)
                .then((response) => {
                    this.closePopup()
                    this.toast('info', response)
                    this.$emit('updateData')
                })
                .catch((error) => {
                    this.toast('warning', error.response.data.message)
                })
                .finally(() => (this.loading = false))
        },
    },
}
</script>

<style lang="scss">
.moving-popup {
    max-width: 560px;
    padding: 8px;

    $parent: &;

    &__header {
        justify-content: center;
    }

    &__subheader {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
    }

    &__heading {
        font-weight: 600;
        font-size: 24px;
        line-height: 32px;
        letter-spacing: 0.2px;
        color: #1c262f;
    }

    &__subheading {
        font-weight: 600;
        font-size: 18px;
        line-height: 24px;
        letter-spacing: 0.2px;
        margin-bottom: 0;
        color: #1c262f;
    }

    &__icon {
        color: #1c262f;
        margin-right: 8px;
    }

    &__footer {
        justify-content: center;
    }

    &__button {
        min-width: 140px;

        &--cancel {
            margin-right: auto;
        }
    }

    &__list {
        max-height: 40vh;
        overflow-y: auto;
    }

    &__new {
        margin-top: 10px;
    }

    &__type {
        display: flex;
        flex-direction: column;
        padding: 10px 16px;
    }

    &__label {
        display: flex;
        align-items: center;
    }

    &__radio {
        margin-right: 8px;
    }
}
</style>
